.h1 {
  display: table;
  margin: 40px auto;
  color: rgb(127, 127, 127);
  font: 30px Helvetica;
  letter-spacing: 2px;
  text-transform: uppercase;
  letter-spacing: 10px;
}

.form {
  display: table;
  margin: 40px auto;
  background-color: #fff;
  padding: 25px;
  align-items: center;
  justify-content: center;
}

.input {
  font: 18px Helvetica, Arial, sans-serif;
  box-sizing: border-box;
  display: block;
  border: none;
  padding: 20px;
  width: 300px;
  margin-bottom: 20px;
  font-size: 18px;
  outline: none;
  transition: all 0.2s ease-in-out;
}
.textarea {
  font: 18px Helvetica, Arial, sans-serif;
  box-sizing: border-box;
  display: block;
  border: none;
  padding: 20px;
  width: 300px;
  margin-bottom: 20px;
  font-size: 18px;
  outline: none;
  transition: all 0.2s ease-in-out;
}

.placeholder {
  transition: all 0.2s ease-in-out;
  color: #999;
  font: 18px Helvetica, Arial, sans-serif;
}

.textarea {
  height: 200px;
}

.button {
  transition: all 0.2s ease-in-out;
  font: 18px Helvetica, Arial, sans-serif;
  border: none;
  background: #b87333;
  color: #fff;
  padding: 20px;
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 5px;
  padding: 1rem;
  margin-top: 1rem;
  max-width: 600px;
}

.button:hover {
  background: #bd8958;
  cursor: pointer;
}

.buttonDiv {
  display: flex;
  justify-content: center;
}

a {
  color: #242424;
  text-decoration: none;
  text-shadow: 1rem;
}

a:hover {
  color: #fff;
}

.content-info {
  display: flex;
  color: rgb(0, 0, 0);
  padding: 20px;
  margin: 20px;
}

h5 {
  text-align: center;
}
