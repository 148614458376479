.navbar-container {
  margin: 0px;
  display: flex;
  align-items: center;
  height: 80px;
  max-width: 100%;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(8, auto);
  grid-gap: 15px;
  list-style: none;
  text-align: center;
  width: 90vw;
  justify-content: end;
  margin-right: 0;
}
.navbar {
  background: linear-gradient(90deg, #fff 0%, #b87333 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  border-bottom: 1px solid #d9d9d9;
  letter-spacing: 0.075em;
  height: 5rem;
}

.navbar-logo {
  color: #b87333;
  align-items: center;
  margin-left: 10px;
  height: 1.8rem;
  width: auto;
  cursor: pointer;
  text-decoration: none;
}

.nav-links {
  color: #fff;
  display: flex;
  font-size: 1rem;
  align-items: center;
  text-decoration: none;
  height: 100%;
}

.nav-links:hover {
  border-bottom: 4px solid #fff;
  color: #242222;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 20vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #242222;
    padding-left: 0;
    justify-content: center;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .navbar-logo {
    position: absolute;
    left: 0;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }
}
